import React from 'react';
import { FabProps, Fab, Grid, GridProps, ChipProps, Chip, TypographyProps, Typography, LinkProps, Link, PaperProps, Paper, BoxProps, Box, TableRow, TableRowProps, ButtonProps, Button, CircularProgressProps, CircularProgress } from '@material-ui/core';

interface IFabWithHiddenProps {
    hidden?: boolean;
}

export class ButtonWithHidden extends React.Component<ButtonProps & IFabWithHiddenProps> {
    render(){
        const {
            hidden,
            ...buttonProps
        } = this.props;
    
        if (hidden === undefined || hidden) {
            return <></>;
        }
        else {
            return <Button {...buttonProps} />;
        }
    }
}

export class FabWithHidden extends React.Component<FabProps & IFabWithHiddenProps> {
    render(){
        const {
            hidden,
            ...buttonProps
        } = this.props;
    
        if (hidden === undefined || hidden) {
            return <></>;
        }
        else {
            return <Fab {...buttonProps} />;
        }
    }
}

export class GridWithHidden extends React.Component<GridProps & IFabWithHiddenProps> {
    render(){
        const {
            hidden,
            ...buttonProps
        } = this.props;
    
        if (hidden === undefined || hidden) {
            return <></>;
        }
        else {
            return <Grid {...buttonProps} />;
        }
    }
}

export class ChipWithHidden extends React.Component<ChipProps & IFabWithHiddenProps> {
    render(){
        const {
            hidden,
            ...buttonProps
        } = this.props;
    
        if (hidden === undefined || hidden) {
            return <></>;
        }
        else {
            return <Chip {...buttonProps} />;
        }
    }
}

interface AddedTypographyProps {
    component?: React.ElementType<any>;
}
export class TypographyWithHidden extends React.Component<TypographyProps & IFabWithHiddenProps & AddedTypographyProps> {
    render(){
        const {
            hidden,
            ...buttonProps
        } = this.props;
    
        if (hidden === undefined || hidden) {
            return <></>;
        }
        else {
            return <Typography {...buttonProps} />;
        }
    }
}

export class LinkWithHidden extends React.Component<LinkProps & IFabWithHiddenProps> {
    render(){
        const {
            hidden,
            ...buttonProps
        } = this.props;
    
        if (hidden === undefined || hidden) {
            return <></>;
        }
        else {
            return <Link {...buttonProps} />;
        }
    }
}

export class PaperWithHidden extends React.Component<PaperProps & IFabWithHiddenProps> {
    render(){
        const {
            hidden,
            ...buttonProps
        } = this.props;
    
        if (hidden === undefined || hidden) {
            return <></>;
        }
        else {
            return <Paper {...buttonProps} />;
        }
    }
}

export class BoxWithHidden extends React.Component<BoxProps & IFabWithHiddenProps> {
    render(){
        const {
            hidden,
            ...buttonProps
        } = this.props;
    
        if (hidden === undefined || hidden) {
            return <></>;
        }
        else {
            return <Box {...buttonProps} />;
        }
    }
}

export class TableRowWithHidden extends React.Component<TableRowProps & IFabWithHiddenProps> {
    render(){
        const {
            hidden,
            ...buttonProps
        } = this.props;
    
        if (hidden === undefined || hidden) {
            return <></>;
        }
        else {
            return <TableRow {...buttonProps} />;
        }
    }
}

export class CircularProgressWithHidden extends React.Component<CircularProgressProps & IFabWithHiddenProps> {
    render(){
        const {
            hidden,
            ...buttonProps
        } = this.props;
    
        if (hidden === undefined || hidden) {
            return <></>;
        }
        else {
            return <CircularProgress {...buttonProps} />;
        }
    }
}