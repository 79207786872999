import { Guid } from 'guid-typescript';
import { Action, ActionCreator, Dispatch, Reducer } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IAppState, neverReached } from '.';
import { getCurrentTimeEpochMilliseconds } from '../util';
import { handleIdempotentApiCall } from './apiTransactionHandler';
import { IShowInfoSyncSuccessAction } from './sync';
import { ISetCurrentShowCodeAction } from './urlWrapper';

// Store
export interface ISongRequest {
    readonly songName: string;
    readonly artistName: string;
    readonly dedication?: string;
    readonly tipCents?: number;
    readonly stripePaymentId?: string;
}

export interface IShowInfo {
    showId: string;
    showCode: string;
    showName: string;
    allowTips: boolean;
}

export type RequestStatus = 'NONE' | 'SENDING' | 'SUCCESS' | 'FAILED';

export interface IShowInfoState {
    currentShowInfo?: IShowInfo; 
    lastFilterChangeEpochMilliseconds: number;
    songRequestStatus: RequestStatus;
    songRequestError?: string;
}

const initialShowInfoState: IShowInfoState = {
    lastFilterChangeEpochMilliseconds: 0,
    songRequestStatus: 'NONE',
};

// Actions
export interface ISendSongRequestAction extends Action<'SendSongRequest'> {
    transactionId: string;
}

export interface ISongRequestSuccessAction extends Action<'SongRequestSuccess'> {}

export interface ISongRequestFailedAction extends Action<'SongRequestFailed'> {
    reason: string;
}

export type UserActions =
    | ISendSongRequestAction
    | ISongRequestSuccessAction
    | ISongRequestFailedAction
    | ISetCurrentShowCodeAction
    | IShowInfoSyncSuccessAction;

// Action Creators
export const sendSongRequestActionCreator: ActionCreator<
    ThunkAction<
        Promise<void>,            // The type of the last action to be dispatched - will always be promise<T> for async actions
        IAppState,                // The type for the data within the last action
        null,                     // The type of the parameter for the nested function 
        ISongRequestSuccessAction // The type of the last action to be dispatched
    >
> = (songRequest: ISongRequest) => {
    return async (dispatch: Dispatch, getState: () => IAppState) => {
        const currentShow: IShowInfo | undefined = getState().showInfo.currentShowInfo;
        if (currentShow === undefined) {
            return;
        }

        const transactionId: string = Guid.create().toString();
        dispatch({
            type: 'SendSongRequest',
            transactionId: transactionId,
        } as ISendSongRequestAction);

        await handleIdempotentApiCall(
            `${process.env.REACT_APP_API_ROOT_URL}show/request-song`,
            {
                ...songRequest,
                showCode: currentShow.showCode,
            },
            204,
            () => {
                dispatch({
                    type: 'SongRequestSuccess',
                } as ISongRequestSuccessAction);
            },
            (code, message) => {
                dispatch({
                    type: 'SongRequestFailed',
                    reason: message,
                } as ISongRequestFailedAction);
            }
        );
    };
};

// Reducers
export const showInfoReducer: Reducer<IShowInfoState, UserActions> = (
    state = initialShowInfoState,
    action,
) => {
    switch (action.type) {
        case 'SetCurrentShowCode': {
            return {
                ...state,
                lastFilterChangeEpochMilliseconds: getCurrentTimeEpochMilliseconds(),
            };
        }
        case 'ShowInfoSyncSuccess': {
            return {
                ...state,
                currentShowInfo: action.showInfo,
            };
        }
        case 'SendSongRequest': {
            return {
                ...state,
                songRequestStatus: 'SENDING',
                songRequestError: undefined,
            };
        }
        case 'SongRequestSuccess': {
            return {
                ...state,
                songRequestStatus: 'SUCCESS',
                songRequestError: undefined,
            };
        }
        case 'SongRequestFailed': {
            return {
                ...state,
                songRequestStatus: 'FAILED',
                songRequestError: action.reason,
            };
        }
        default:
            neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
    }
    return state;
};
