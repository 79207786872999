import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IOfflineAppState } from '../../store';
import { startSyncIntervalActionCreator } from '../../store/sync';

interface IProps {
    children: React.ReactNode;
    startSync: () => void,
}

const SyncWrapper: React.FC<IProps> = ({
    children,
    startSync,
}) => {
    startSync();

    return (
        <>{children}</>
    );
}

const mapStateToProps = (store: IOfflineAppState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        startSync: () => dispatch(startSyncIntervalActionCreator()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SyncWrapper);