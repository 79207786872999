import { Action, Reducer, ActionCreator, AnyAction } from 'redux';
import { neverReached, IAppState } from '.';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import axios, { AxiosResponse } from 'axios';
import { getCurrentTimeEpochMilliseconds } from '../util';
import { IShowInfo } from './showInfo';
import { ISongRequest } from './showPlaylist';

// Store
const dataTimeoutPeriodMilliseconds: number = (Number(process.env.REACT_APP_REFRESH_SYNC_DATA_TIMEOUT_SECONDS) * 1000);
const reattemptTimeoutPeriodMilliseconds: number = (Number(process.env.REACT_APP_REATTEMPT_SYNC_TIMEOUT_SECONDS) * 1000);

export interface SyncStatus {
    syncing: boolean;
    fromDataTimeout: boolean;
    lastSyncAttemptTimeEpochMilliseconds?: number;
    lastSyncSuccessTimeEpochMilliseconds: number;
}

const initialSyncStatus: SyncStatus = {
    syncing: false,
    fromDataTimeout: false,
    lastSyncAttemptTimeEpochMilliseconds: undefined,
    lastSyncSuccessTimeEpochMilliseconds: 0,
};

export interface ISyncState {
    readonly currentlySyncingShowInfo: SyncStatus;
    readonly currentlySyncingPlaylist: SyncStatus;
}

const initialSyncState: ISyncState = {
    currentlySyncingShowInfo: initialSyncStatus,
    currentlySyncingPlaylist: initialSyncStatus,
};

let timer: NodeJS.Timeout | undefined = undefined;

interface IShowPlaylistResponse {
    songs: ISongRequest[];
}

// Actions
export interface IShowInfoSyncStartAction extends Action<'ShowInfoSyncStart'> {
    fromDataTimeout: boolean;
}

export interface IShowInfoSyncSuccessAction extends Action<'ShowInfoSyncSuccess'> {
    showInfo: IShowInfo;
}

export interface IShowPlaylistSyncStartAction extends Action<'ShowPlaylistSyncStart'> {
    fromDataTimeout: boolean;
}

export interface IShowPlaylistSyncSuccessAction extends Action<'ShowPlaylistSyncSuccess'> {
    songs: ISongRequest[];
}

export type SyncActions =
  | IShowInfoSyncStartAction
  | IShowInfoSyncSuccessAction
  | IShowPlaylistSyncStartAction
  | IShowPlaylistSyncSuccessAction;

// Action Creators
export const startSyncIntervalActionCreator: ActionCreator<
    ThunkAction<
        Promise<void>,           // The type of the last action to be dispatched - will always be promise<T> for async actions
        IAppState,               // The type for the data within the last action
        string,                  // The type of the parameter for the nested function 
        IShowInfoSyncStartAction // The type of the last action to be dispatched
    >
> = () => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
        if (timer !== undefined) {
            return;
        }
        
        timer = setInterval(() => dispatch(syncActionCreator()), 100);
    };
};

// Check if a sync should be done based on the given information
const doSync = (
    currentTimeEpochMilliseconds: number,
    syncStatus: SyncStatus, 
    dataIsViewed: boolean, 
    lastFilterChangeEpochMilliseconds: number
) => {
    const dataTimeout: boolean = (currentTimeEpochMilliseconds - syncStatus.lastSyncSuccessTimeEpochMilliseconds) > dataTimeoutPeriodMilliseconds;
    const dataChangedSinceLastSync: boolean = 
        syncStatus.lastSyncSuccessTimeEpochMilliseconds <= lastFilterChangeEpochMilliseconds;
    const syncReattempt: boolean = syncStatus.lastSyncAttemptTimeEpochMilliseconds !== undefined &&
        (currentTimeEpochMilliseconds - syncStatus.lastSyncAttemptTimeEpochMilliseconds) > reattemptTimeoutPeriodMilliseconds;
    
    return {
        sync: (!syncStatus.syncing || syncReattempt) &&
            dataIsViewed && 
            (dataTimeout || dataChangedSinceLastSync),
        isDataTimeout: dataTimeout && !dataChangedSinceLastSync,
    };
}

export const syncActionCreator: ActionCreator<
    ThunkAction<
        Promise<void>,           // The type of the last action to be dispatched - will always be promise<T> for async actions
        IAppState,               // The type for the data within the last action
        string,                  // The type of the parameter for the nested function 
        IShowInfoSyncStartAction // The type of the last action to be dispatched
    >
> = () => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>, getState: () => IAppState) => {
        const currentTimeEpochMilliseconds: number = getCurrentTimeEpochMilliseconds();
        const currentShowCode: string | undefined = getState().urlWrapper.currentShowCode;

        const doShowSync = doSync(
            currentTimeEpochMilliseconds, 
            getState().syncState.currentlySyncingShowInfo, 
            true, 
            getState().showInfo.lastFilterChangeEpochMilliseconds);
        if (doShowSync.sync && currentShowCode !== undefined) {
            const startSyncAction: IShowInfoSyncStartAction = {
                type: 'ShowInfoSyncStart',
                fromDataTimeout: doShowSync.isDataTimeout,
            };
            dispatch(startSyncAction);
        
            axios.get(
                process.env.REACT_APP_API_ROOT_URL + `show/${currentShowCode}/details`,
                {
                    headers: { 
                        'Access-Control-Allow-Origin': '*'
                    }
                }
            ).then((res: AxiosResponse<IShowInfo>) => {
                const syncSuccessAction: IShowInfoSyncSuccessAction = {
                    type: 'ShowInfoSyncSuccess',
                    showInfo: res.data as IShowInfo,
                };
                dispatch(syncSuccessAction);
            });
        }

        const doShowPlaylistSync = doSync(
            currentTimeEpochMilliseconds, 
            getState().syncState.currentlySyncingPlaylist, 
            true, 
            getState().showPlaylist.lastFilterChangeEpochMilliseconds);
        if (doShowPlaylistSync.sync && currentShowCode !== undefined) {
            const startSyncAction: IShowPlaylistSyncStartAction = {
                type: 'ShowPlaylistSyncStart',
                fromDataTimeout: doShowSync.isDataTimeout,
            };
            dispatch(startSyncAction);
        
            axios.get(
                process.env.REACT_APP_API_ROOT_URL + `show/${currentShowCode}/playlist`,
                {
                    headers: { 
                        'Access-Control-Allow-Origin': '*'
                    }
                }
            ).then((res: AxiosResponse<IShowPlaylistResponse>) => {
                const syncSuccessAction: IShowPlaylistSyncSuccessAction = {
                    type: 'ShowPlaylistSyncSuccess',
                    songs: (res.data as IShowPlaylistResponse).songs,
                };
                dispatch(syncSuccessAction);
            });
        }
    };
};

// Reducers
export const syncReducer: Reducer<ISyncState, SyncActions> = (
    state = initialSyncState,
    action,
) => {
    switch (action.type) {
        case 'ShowInfoSyncStart': {
            return {
                ...state,
                currentlySyncingShowInfo: {
                    ...state.currentlySyncingShowInfo,
                    syncing: true,
                    fromDataTimeout: action.fromDataTimeout,
                    lastSyncAttemptTimeEpochMilliseconds: getCurrentTimeEpochMilliseconds(),
                }
            };
        }
        case 'ShowInfoSyncSuccess': {
            return {
                ...state,
                currentlySyncingShowInfo: {
                    syncing: false,
                    fromDataTimeout: false,
                    lastSyncSuccessTimeEpochMilliseconds: getCurrentTimeEpochMilliseconds(),
                }
            };
        }
        case 'ShowPlaylistSyncStart': {
            return {
                ...state,
                currentlySyncingPlaylist: {
                    ...state.currentlySyncingPlaylist,
                    syncing: true,
                    fromDataTimeout: action.fromDataTimeout,
                    lastSyncAttemptTimeEpochMilliseconds: getCurrentTimeEpochMilliseconds(),
                }
            };
        }
        case 'ShowPlaylistSyncSuccess': {
            return {
                ...state,
                currentlySyncingPlaylist: {
                    syncing: false,
                    fromDataTimeout: false,
                    lastSyncSuccessTimeEpochMilliseconds: getCurrentTimeEpochMilliseconds(),
                }
            };
        }
        default:
            neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
    }
    return state;
};