import React from 'react';
import { WithStyles, createStyles, withStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Typography, Card, CardContent, CardHeader, Grid, Checkbox, CircularProgress } from '@material-ui/core';
import { ISongRequestLocal, updateSongPlayedStatusActionCreator } from '../../store/showPlaylist';
import { priceInCadCentsToString, stringNullEmptyOrUndefined } from '../../util';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

const styles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
        marginBottom: theme.spacing(1),
        maxWidth: theme.spacing(160),
    },
    header: {
        textAlign: 'left',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    totalLabel: {
        fontWeight: 600,
    },
    totalValue: {
    },
    tip: {
        fontStyle: 'italic',
    }
});

interface IProps extends WithStyles<typeof styles> {
    request: ISongRequestLocal;
    updateRequestPlayedStatus: (songRequestId: string, newValue: boolean) => void;
}

const ShowRequestCard: React.FC<IProps> = ({
    request,
    updateRequestPlayedStatus,
    classes,
}) => {
    const totals = React.useMemo(() => {
        return [
            { label: "Total Requests", value: request.totalRequests },
            { label: "Total Tips", value: priceInCadCentsToString(request.totalTipsCents) },
        ];
    }, [request.totalRequests, request.totalTipsCents]);

    const requestDetails = React.useMemo(() => {
        return request.requests.filter(r => !stringNullEmptyOrUndefined(r.dedication)).sort((a, b) => b.tipInCents - a.tipInCents);
    }, [request.requests]);

    const songPlayed: 'LOADING' | 'PLAYED' | 'QUEUED' = React.useMemo(() => {
        if (request.updateStatus === 'SENDING') {
            return 'LOADING';
        }

        for (const r of request.requests) {
            if (r.played) {
                return 'PLAYED';
            }
        }

        return 'QUEUED';
    }, [request.requests, request.updateStatus]);

    const handleCheckboxClick = React.useCallback(() => {
        updateRequestPlayedStatus(request.id, songPlayed === 'QUEUED');
    }, [updateRequestPlayedStatus, songPlayed, request.id]);

    const cardAvatar: React.ReactNode = React.useMemo(() => {
        if (songPlayed === 'LOADING') {
            return <CircularProgress />
        }

        return <Checkbox checked={songPlayed === 'PLAYED'} onClick={handleCheckboxClick} />;
    }, [songPlayed, handleCheckboxClick]);

    return <Card className={classes.root}>
        <CardHeader
            className={classes.header}
            title={request.song}
            subheader={request.artist}
            avatar={cardAvatar}
        />
        <CardContent>
            <Grid container spacing={1} justify="center" alignItems="center" direction="row">
                {totals.map(t => <Grid item>
                    <Grid container direction="column">
                        <Grid item><Typography color="secondary" className={classes.totalLabel}>{t.label}</Typography></Grid>
                        <Grid item><Typography className={classes.totalValue}>{t.value}</Typography></Grid>
                    </Grid>
                </Grid>)}
            </Grid>
            {requestDetails.length > 0 ? <>
                <Typography color="secondary" className={classes.totalLabel}>Requests</Typography>
                {requestDetails.map(r =><Grid container direction="column">
                    <Grid item>
                        <Typography><span className={classes.tip}>{r.tipInCents === 0 ? "No Tip" : priceInCadCentsToString(r.tipInCents)}</span> - {r.dedication}</Typography>
                    </Grid>
                </Grid>)}</> 
            : <></>}
        </CardContent>
    </Card>;
}

const mapStateToProps = (store: IAppState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        updateRequestPlayedStatus: (songRequestId: string, newValue: boolean) => dispatch(updateSongPlayedStatusActionCreator(songRequestId, newValue)),
    };
};

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShowRequestCard));