import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles, Typography, Grid } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { IAppState } from '../store';
import { AnyAction } from 'redux';

// min height of 48 to work with AppBar
export const footerHeight: number = 40;
export const footerPadding: number = 10;

const styles = (theme: Theme) => createStyles({
    footer: {
        width:  `calc(100% - (2 * ${footerPadding}px))`,
        padding: footerPadding,
        height: footerHeight,
        position: 'absolute',
        textAlign: 'center',
        left: 0,
        bottom: 0,
    },
    link: {
        color: theme.palette.text.primary,
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
});

interface IProps extends WithStyles<typeof styles> {
}

const Footer: React.FC<IProps> = ({
    classes,
}) => {
    return (
        <footer className={classes.footer}>
            <Grid container direction="row" alignItems="center" justify="center">
                <Typography>
                    &copy; TyFi Consulting Inc. {new Date().getFullYear()}
                </Typography>
            </Grid>
        </footer>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
    };
};

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Footer));