import React, { ReactNode } from 'react';
import { WithStyles, createStyles, withStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Typography, Grid, Link } from '@material-ui/core';
import { connect } from 'react-redux';
import { IAppState } from '../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LanguageIcon from '@material-ui/icons/Language';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

const styles = (theme: Theme) => createStyles({
    icon: {
        fontSize: theme.spacing(7),

        "&.facebook": {
            color: "#3B5998",
        },

        "&.youtube": {
            color: "#FF0000",
        },

        "&.instagram": {
            color: "#833AB4",
        },

        "&.twitter": {
            color: "#1DA1F2",
        },

        "&.website": {
        },
    }
});

type SocialMediaPlatform = "facebook" | "twitter" | "instagram" | "youtube" | "website";

interface ISocialLink {
    url: string;
    type: SocialMediaPlatform;
}

const SocialMedias: ISocialLink[] = [
    {
        url: "https://www.facebook.com/duelingpianokings",
        type: "facebook"
    },
    {
        url: "https://www.instagram.com/duelingpianokings",
        type: "instagram"
    },
    {
        url: "https://twitter.com/duelpianokings",
        type: "twitter"
    },
    {
        url: "https://www.youtube.com/channel/UC2kMnAtZ5ns7xoA0HZTP3BQ",
        type: "youtube"
    },
];



interface IProps extends WithStyles<typeof styles> {
}

const FollowMeSocialMedia: React.FC<IProps> = ({
    classes,
}) => {
    const GetSocialMediaIcon = React.useCallback((type: SocialMediaPlatform): ReactNode => {
        switch (type) {
            case "facebook":
                return <FacebookIcon className={`${classes.icon} facebook`} />;
            case "twitter":
                return <TwitterIcon className={`${classes.icon} twitter`} />;
            case "instagram":
                return <InstagramIcon className={`${classes.icon} instagram`} />;
            case "youtube":
                return <YouTubeIcon className={`${classes.icon} youtube`} />;
            case "website":
                return <LanguageIcon className={`${classes.icon} website`} />;
        }
    }, [classes]);
    
    return (
        <Grid container alignItems="center" direction="column">
            <Grid item>
                <Typography variant="h5">Follow Us on Social Media</Typography>
            </Grid>
            <Grid item>
                <Grid container alignItems="center" direction="row">
                    {SocialMedias.map(s => <Link href={s.url} target="_blank">{GetSocialMediaIcon(s.type)}</Link>)}
                </Grid>
            </Grid>    
        </Grid>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
    };
};

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps,
)(FollowMeSocialMedia));