import React from 'react';
import { 
    Theme, 
    createStyles, 
    WithStyles, 
    withStyles, 
    Paper, 
    Typography, 
    CircularProgress,
    Grid,
    Fade
} from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
    root: {
        background: theme.palette.secondary.light,
        width: '100%',
    },
    updatingTypography: {
        marginRight: theme.spacing(1),
        fontColor: theme.palette.secondary.contrastText,
    }
});

interface IProps extends WithStyles<typeof styles> {
    loading: boolean;
    unmountOnExit: boolean;
    message?: string;
}

const UpdatingMessage: React.FC<IProps> = ({
    loading,
    unmountOnExit,
    message,
    classes,
}) => {
    return (
        <Fade
            in={loading}
            unmountOnExit={unmountOnExit}
        >
            <Paper className={classes.root} square={true}>
                <Grid container direction="row" alignItems="center" justify="center">
                    <Typography className={classes.updatingTypography}>
                        {message === undefined ? 'Updating ...' : message}
                    </Typography>
                    <CircularProgress />
                </Grid>
            </Paper>
        </Fade>
    );
}

export default withStyles(styles)(UpdatingMessage);