import React from 'react';
import { WithStyles, createStyles, withStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Typography, Grid, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { IAppState } from '../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootUrls } from '.';
import { IShowInfo } from '../store/showInfo';
import UpdatingMessage from '../components/UserInterface/UpdatingMessage';
import FollowMeSocialMedia from '../components/FollowMeSocialMedia';

const styles = (theme: Theme) => createStyles({
    root: {
        marginTop: theme.spacing(3),
        textAlign: 'center',
    },
});

interface IProps extends WithStyles<typeof styles> {
    showCode: string | undefined;
    showInfo: IShowInfo | undefined;
}

const HomePage: React.FC<IProps> = ({
    classes,
    showCode,
    showInfo,
}) => {
    if (showCode === undefined) {
        return <></>;
    }
    else if (showInfo === undefined) {
        return <UpdatingMessage loading={true} unmountOnExit={true} message="Loading ..." />;
    }
    else {
        return (
            <Grid container alignItems="center" direction="column" className={classes.root}>
                <Grid item>
                    <Typography variant="h3">Welcome to {showInfo.showName}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4">Thanks for attending the show! Click the button below to start requesting music!</Typography>
                    <Button
                        href={RootUrls.showRequestPage(showCode)}
                        color="primary"
                        variant="contained"
                    >
                        Request a Song
                    </Button>
                </Grid>
                <Grid item>
                    <FollowMeSocialMedia />
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (store: IAppState) => {
    return {
        showCode: store.urlWrapper.currentShowCode,
        showInfo: store.showInfo.currentShowInfo,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
    };
};

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps,
)(HomePage));