import React from 'react';
import { WithStyles, createStyles, withStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Typography, Grid, TextField, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { IAppState } from '../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { push } from 'connected-react-router';
import { RootUrls } from '.';

const styles = (theme: Theme) => createStyles({
    root: {
        margin: theme.spacing(3),
        textAlign: 'center',
    },
});

interface IProps extends WithStyles<typeof styles> {
    push: (url: string) => void;
}

const HomePage: React.FC<IProps> = ({
    classes,
    push,
}) => {
    const [showCode, setShowCode] = React.useState<string>("");

    const onClick = React.useCallback(() => {
        push(RootUrls.showHomePage(showCode));
    }, [showCode, push]);

    const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setShowCode(e.target.value);
    }, [setShowCode]);

    return (
        <Grid container alignItems="center" direction="column" className={classes.root}>
            <Typography variant="h4">Hello! Welcome to TheHubuzz!</Typography>
            <Typography variant="h5">Please enter your shows code below</Typography>
            <TextField label="Show Code" variant="filled" value={showCode} onChange={handleChange} />
            <Button color="primary" variant="contained" onClick={onClick}>Lets go!</Button>
        </Grid>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        push: (url: string) => dispatch(push(url)),
    };
};

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps,
)(HomePage));