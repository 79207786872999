import red from '@material-ui/core/colors/red';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

export const disabledCSS = {
    opacity: 0.4,
    cursor: 'context-menu',
}

// A custom theme for this app
const primaryColor: string = '#4d4fff';

const theme = responsiveFontSizes(createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: primaryColor,
        },
        secondary: {
            main: '#8a8cff',
        },
        error: {
            main: red.A400,
        },
        contrastThreshold: 3,
    },
}));

export const Scrollbars = {
    '&::-webkit-scrollbar-track': {
        borderRadius: '10px',
        boxShadow: 'inset 0 0 5px grey',
    },
    '&::-webkit-scrollbar': {
        width: '18px',
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: '12px',
        backgroundColor: theme.palette.primary.main,
    },
};

export const ScrollbarSubtle = {
    '&::-webkit-scrollbar': {
        height: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: '16px',
        backgroundColor: theme.palette.grey[600],
    },
};

export const ScrollbarsThin = {
    '&::-webkit-scrollbar-track': {
        borderRadius: '10px',
    },
    '&::-webkit-scrollbar': {
        width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: '12px',
        backgroundColor: theme.palette.primary.main,
    },
};

export default theme;