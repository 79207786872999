export function setIntersect<T>(s1: Set<T>, s2: Set<T>): Set<T> {
    return new Set([...s1].filter(s => s2.has(s)));
}

export function dictIntersect<T>(d1: { [key: string]: T }, d2: { [key: string]: T }) {
    const newDict: { [key: string]: T } = {};

    Object.keys(d1).forEach(k => {
        newDict[k] = d1[k];
    });

    Object.keys(d2).forEach(k => {
        newDict[k] = d2[k];
    });

    return newDict;
}

export function setToIterable<T>(s: Set<T>): T[] {
    return s.size > 0 ? [...s] : [];
}

export function getCurrentTimeEpochMilliseconds() : number {
    return Date.now();
}

export function stringNullEmptyOrUndefined(s: string | null | undefined): boolean {
    return s === '' || anyNullOrUndefined(s);
}

export function anyNullOrUndefined(s: any | null | undefined): boolean {
    return s === null || s === undefined;
}

export function numberNullEmptyUndefinedOrZero(n: number | null | undefined): boolean {
    return n === 0 || n === null || n === undefined;
}

export function wait(timeoutMilliseconds: number) {
    return new Promise(resolve => {
        setTimeout(resolve, timeoutMilliseconds);
    });
}

export const priceInCadCentsToString = (priceInCentsCad: number | null | undefined) => {
    if (priceInCentsCad === null) {
        return 'Price varies';
    }

    if (priceInCentsCad === undefined) {
        return 'No Value';
    }

    if (priceInCentsCad === 0) {
        return '-';
    }

    return `$${(priceInCentsCad / 100).toFixed(2)}`;
}

export const priceInCadCentsNumber = (priceInCentsCad: number | null): number | null => {
    if (priceInCentsCad === null) {
        return null;
    }

    return priceInCentsCad / 100;
}

export const getNullNumberAsNumber = (num: number | null | undefined, def: 'min' | 'max'): number => {
    if (num === null || num === undefined) {
        return def === 'max' ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;
    }

    return num;
}

interface IHasOrder {
    order: number | null | undefined;
}

export const objectWithNullOrderComparator = (a: IHasOrder, b: IHasOrder): number => {
    if (a.order === b.order ||
        (a.order === null && b.order === undefined) ||
        (a.order === undefined && b.order === null)) {
        return 0;
    }

    if (a.order === null || a.order === undefined) {
        return 1;
    }

    if (b.order === null || b.order === undefined) {
        return -1;
    }

    return a.order > b.order ? 1 : -1;
};

export const extractErrorFrom400Response = (res: any): string[] => {
    if (res.data !== undefined && res.data.length > 0 && res.data[0] !== undefined) {
        return res.data.map((d: any) => d.description);
    }
    else if (res.data.errors !== undefined) {
        const errors: Set<string> = new Set<string>();
        for (const key of Object.keys(res.data.errors)) {
            errors.add(res.data.errors[key]);
        }

        return [ ...errors];
    }

    return [];
}

export type NotificationsStatus = 'Unavailable' | 'Disabled' | 'Enabled';
export const getBrowserNotificationStatus: () => NotificationsStatus = () => {
    if (!('Notification' in window)) {
        return 'Unavailable';
    }
    else if (Notification.permission === 'granted') {
        return 'Enabled';
    }
    else {
        return 'Disabled';
    }
}