import React from 'react';
import { WithStyles, createStyles, withStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Typography, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { IAppState } from '../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import SendRequestForm from '../components/SendRequestForm';
import { usePrevious } from '../usePrevious';
import { IShowInfo, RequestStatus } from '../store/showInfo';
import { Redirect } from 'react-router';
import { RootUrls } from '.';

const styles = (theme: Theme) => createStyles({
    root: {
        margin: theme.spacing(3),
        textAlign: 'center',
    },
});

interface IProps extends WithStyles<typeof styles> {
    songRequestStatus: RequestStatus;
    currentShowInfo: IShowInfo | undefined;
}

const ShowRequestsPage: React.FC<IProps> = ({
    songRequestStatus,
    currentShowInfo,
    classes,
}) => {
    const prevStatus: RequestStatus | undefined = usePrevious(songRequestStatus);

    if (currentShowInfo === undefined) {
        return <></>;
    }

    if (prevStatus !== 'SUCCESS' && songRequestStatus === 'SUCCESS') {
        return <Redirect to={RootUrls.showRequestThanksPage(currentShowInfo.showCode)} />;
    }

    return (
        <Grid container alignItems="center" direction="column" className={classes.root}>
            <Typography variant="h5">Send a request</Typography>
            <SendRequestForm />
        </Grid>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        songRequestStatus: store.showInfo.songRequestStatus,
        currentShowInfo: store.showInfo.currentShowInfo,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
    };
};

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShowRequestsPage));