import { LocationChangeAction } from 'connected-react-router';
import { ActionCreator, Reducer, AnyAction, Action } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { neverReached, IAppState } from '.';

// Store
export interface IUrlWrapperState {
    currentShowCode?: string;
}

const initialShowInfoState: IUrlWrapperState = {
};

// Actions
export interface ISetCurrentShowCodeAction extends Action<'SetCurrentShowCode'> {
    showCode: string | undefined;
}

export type UserActions =
    | ISetCurrentShowCodeAction
    | LocationChangeAction;

// Action Creators
export const updateUrlParameters: ActionCreator<
    ThunkAction<
        Promise<void>,           // The type of the last action to be dispatched - will always be promise<T> for async actions
        IAppState,               // The type for the data within the last action
        null,                    // The type of the parameter for the nested function 
        ISetCurrentShowCodeAction // The type of the last action to be dispatched
    >
> = (newShowCode?: string) => {
    return async (dispatch: ThunkDispatch<any, any, AnyAction>, getState: () => IAppState) => {
        if (newShowCode !== getState().urlWrapper.currentShowCode) {     
            dispatch({
                showCode: newShowCode,
                type: 'SetCurrentShowCode',
            } as ISetCurrentShowCodeAction);
        }
    };
};

// Reducers
export const urlWrapperReducer: Reducer<IUrlWrapperState, UserActions> = (
    state = initialShowInfoState,
    action,
) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE': {
            return state;
        }
        case 'SetCurrentShowCode': {
            return {
                ...state,
                currentShowCode: action.showCode,
            };
        }
        default:
            neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
    }
    return state;
};