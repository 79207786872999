import React from 'react';
import { connect } from 'react-redux';
import { IOfflineAppState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { updateUrlParameters } from '../../store/urlWrapper';

interface IProps {
    newShowCode?: string;
    children: React.ReactNode;
    updateUrlParameters: (newShowCode?: string) => void;
}

const UrlParameterWrapper: React.FC<IProps> = ({
    newShowCode,
    children,
    updateUrlParameters,
}) => {
    React.useEffect(() => {
        updateUrlParameters(newShowCode);
    }, [newShowCode, updateUrlParameters]);

    return (
        <>
            {children}
        </>
    );
}

const mapStateToProps = (store: IOfflineAppState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        updateUrlParameters: (newShowCode?: string) => dispatch(updateUrlParameters(newShowCode)),
    };
};

export default (connect(
    mapStateToProps,
    mapDispatchToProps,
)(UrlParameterWrapper));