import { reducer as reduxFormReducer, FormStateMap } from 'redux-form';
import { connectRouter, routerMiddleware, RouterState } from 'connected-react-router';
import { combineReducers, createStore, applyMiddleware, Store, compose, StoreEnhancer } from 'redux';
import { History, createBrowserHistory } from 'history';
import { ISyncState, syncReducer } from './sync';
import thunk from 'redux-thunk';
import { IServiceWorkerState, serviceWorkerReducer } from './serviceWorker';
import { AppState as OfflineAppState } from '@redux-offline/redux-offline/lib/types';
import { createOffline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import { IRehydratedState, rehydratedReducer } from './rehydrated';
import { IShowInfoState, showInfoReducer } from './showInfo';
import { IUrlWrapperState, urlWrapperReducer } from './urlWrapper';
import { IShowPlaylistState, showPlaylistReducer } from './showPlaylist';

// state
export interface IAppState {
    readonly form: FormStateMap;
    readonly router: RouterState;
    readonly syncState: ISyncState;
    readonly serviceWorker: IServiceWorkerState;
    readonly rehydrated: IRehydratedState;
    readonly urlWrapper: IUrlWrapperState;
    readonly showInfo: IShowInfoState;
    readonly showPlaylist: IShowPlaylistState;
}

export type IOfflineAppState = 
    IAppState
    & OfflineAppState;

// tslint:disable-next-line:no-empty
export const neverReached = (never: never) => {};

export const history = createBrowserHistory();

const rootReducer = ((history: History) => combineReducers<IAppState>({
    form: reduxFormReducer,
    router: connectRouter(history),
    syncState: syncReducer,
    serviceWorker: serviceWorkerReducer,
    rehydrated: rehydratedReducer,
    urlWrapper: urlWrapperReducer,
    showInfo: showInfoReducer,
    showPlaylist: showPlaylistReducer,
}))(history);

const offlineAppConfig = {
    ...offlineConfig,
    persistOptions: { 
        blacklist: [
            'form',
            'router',
            'syncState',
            'showInfo',
            'showPlaylistReducer',
            'urlWrapper',
        ],
    },
};

const { middleware, enhanceReducer, enhanceStore } = createOffline(offlineAppConfig);

export function configureStore(): Store<IAppState> {
    // This line is suspect, not sure if this is the middleware required
    const store = createStore(
        enhanceReducer(rootReducer), 
        undefined, 
        compose(
            enhanceStore as StoreEnhancer,
            applyMiddleware(middleware, routerMiddleware(history), thunk)));

    return store;
}