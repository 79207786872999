const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneNumberRe = /^\d{10}$/;

export const validEmail = (email: string) => {
    return emailRe.test(String(email).toLowerCase()) ? undefined : 'Please enter a valid email address';
}

export const required = (value: any) => (value || typeof value === 'number' ? undefined : 'Required');

export const requiredWithMessage = (messageIfMissing: string) => (value: any) => (value || typeof value === 'number' ? undefined : messageIfMissing);

export const validPhoneNumber = (phoneNumber: string) => {
    if (phoneNumber === undefined || phoneNumber === null || phoneNumber === "") {
        return undefined;
    }
    return phoneNumberRe.test(String(phoneNumber).toLowerCase()) ? undefined : 'Please enter a valid 10-digit phone number';
}

export const requiredMatches = (idOfElementToMatch: string, errorMessage: string) => (value: any) => {
    const element: HTMLInputElement = document.getElementById(idOfElementToMatch) as HTMLInputElement;
    if (element !== null && element.value !== value) {
        return errorMessage;
    }
    
    return undefined;
}

export const regexWithMessage = (r: RegExp, message: string) => (value: string) => {
    if (!r.test(value)) {
        return message;
    }

    return undefined;
}