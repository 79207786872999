import React from 'react';
import { WithStyles, createStyles, withStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Typography, Grid, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { IAppState } from '../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ISongRequestLocal } from '../store/showPlaylist';
import SongRequestCard from '../components/SongRequestCard';

const styles = (theme: Theme) => createStyles({
    root: {
        margin: theme.spacing(3),
        textAlign: 'center',
    },
    card: {
        maxWidth: 345,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
});

interface IProps extends WithStyles<typeof styles> {
    songs: ISongRequestLocal[];
}

const ShowRequestsThanksPage: React.FC<IProps> = ({
    songs,
    classes,
}) => {
    const [sorting, setSorting] = React.useState<"MostTips" | "MostRequests" | "MostDedications" | "MostRecent">("MostRecent");

    const sortedSongs = React.useMemo(() => {
        return songs
            .sort((a, b) => {
                let saAllPlayed = true;
                for (const sa of a.requests) {
                    if (!sa.played) {
                        saAllPlayed = false;
                        break;
                    }
                }

                let sbAllPlayed = true;
                for (const sb of b.requests) {
                    if (!sb.played) {
                        sbAllPlayed = false;
                        break;
                    }
                }

                if (saAllPlayed && !sbAllPlayed) {
                    return 1;
                }
                else if (!saAllPlayed && sbAllPlayed) {
                    return -1;
                }

                switch (sorting) {
                    case "MostTips":
                        return b.totalTipsCents - a.totalTipsCents;
                    case "MostDedications":
                        return b.totalDedications - a.totalDedications;
                    case "MostRequests":
                        return b.totalRequests - a.totalRequests;
                    case "MostRecent":
                        return b.requestedAtEpochMilliseconds - a.requestedAtEpochMilliseconds;
                    default:
                        return 0;
                }
            });
    }, [songs, sorting]);

    return (
        <Grid container alignItems="center" direction="column" className={classes.root}>
            <Grid item>
                <Grid container direction="row" alignItems="center">
                    <Typography>Sort by:</Typography>
                    <Button variant={sorting === "MostRecent" ? "contained" : "outlined"} color="secondary" onClick={() => setSorting("MostRecent")}>Most Recent</Button>
                    <Button variant={sorting === "MostTips" ? "contained" : "outlined"} color="secondary" onClick={() => setSorting("MostTips")}>Most Tips</Button>
                    <Button variant={sorting === "MostRequests" ? "contained" : "outlined"} color="secondary" onClick={() => setSorting("MostRequests")}>Most Requested</Button>
                    <Button variant={sorting === "MostDedications" ? "contained" : "outlined"} color="secondary" onClick={() => setSorting("MostDedications")}>Most Dedications</Button>
                </Grid>
            </Grid>
            {sortedSongs.map(song => <SongRequestCard key={song.id} request={song} />)}
        </Grid>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        songs: store.showPlaylist.songs,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
    };
};

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShowRequestsThanksPage));