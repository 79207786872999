import { Guid } from 'guid-typescript';
import { Action, ActionCreator, Dispatch, Reducer } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { IAppState, neverReached } from '.';
import { getCurrentTimeEpochMilliseconds } from '../util';
import { handleIdempotentApiCall } from './apiTransactionHandler';
import { RequestStatus } from './showInfo';
import { IShowPlaylistSyncSuccessAction } from './sync';
import { ISetCurrentShowCodeAction } from './urlWrapper';

// Store
export interface ISongRequestDetails {
    id: string;
    dedication: string | undefined;
    tipInCents: number;
    played: boolean;
}

export interface ISongRequest {
    song: string;
    artist: string;
    totalRequests: number;
    totalTipsCents: number;
    totalDedications: number;
    played: boolean;
    requestedAtEpochMilliseconds: number;
    requests: ISongRequestDetails[];
}

export interface ISongRequestLocal extends ISongRequest {
    id: string;
    updateStatus: RequestStatus;
}

export interface IShowPlaylistState {
    songs: ISongRequestLocal[];
    lastFilterChangeEpochMilliseconds: number;
}

const initialShowPlaylistState: IShowPlaylistState = {
    lastFilterChangeEpochMilliseconds: 0,
    songs: [],
};

// Actions
export interface IUpdateSongPlayedStatusSentAction extends Action<'UpdateSongPlayedStatusSent'> {
    transactionId: string;
    songRequestId: string;
}

export interface IUpdateSongPlayedStatusSuccessAction extends Action<'UpdateSongPlayedStatusSuccess'> {
    songRequestId: string;
    newValue: boolean;
}

export interface IUpdateSongPlayedStatusFailedAction extends Action<'UpdateSongPlayedStatusFailed'> {
    songRequestId: string;
    reason: string;
}

export type UserActions =
    | IUpdateSongPlayedStatusSentAction
    | IUpdateSongPlayedStatusSuccessAction
    | IUpdateSongPlayedStatusFailedAction
    | ISetCurrentShowCodeAction
    | IShowPlaylistSyncSuccessAction;

// Action Creators
export const updateSongPlayedStatusActionCreator: ActionCreator<
    ThunkAction<
        Promise<void>,                       // The type of the last action to be dispatched - will always be promise<T> for async actions
        IAppState,                           // The type for the data within the last action
        null,                                // The type of the parameter for the nested function 
        IUpdateSongPlayedStatusSuccessAction // The type of the last action to be dispatched
    >
> = (songRequestId: string, newValue: boolean) => {
    return async (dispatch: Dispatch, getState: () => IAppState) => {
        const song = getState().showPlaylist.songs.filter(s => s.id === songRequestId)[0];

        const transactionId: string = Guid.create().toString();
        dispatch({
            type: 'UpdateSongPlayedStatusSent',
            transactionId: transactionId,
            songRequestId: songRequestId,
        } as IUpdateSongPlayedStatusSentAction);

        await handleIdempotentApiCall(
            `${process.env.REACT_APP_API_ROOT_URL}show/mark-song-played`,
            {
                requestIds: song.requests.map(r => r.id),
                markPlayed: newValue,
            },
            204,
            () => {
                dispatch({
                    type: 'UpdateSongPlayedStatusSuccess',
                    songRequestId: songRequestId,
                    newValue: newValue,
                } as IUpdateSongPlayedStatusSuccessAction);
            },
            (code, message) => {
                dispatch({
                    type: 'UpdateSongPlayedStatusFailed',
                    songRequestId: songRequestId,
                    reason: message,
                } as IUpdateSongPlayedStatusFailedAction);
            }
        );
    };
};

// Reducers
export const showPlaylistReducer: Reducer<IShowPlaylistState, UserActions> = (
    state = initialShowPlaylistState,
    action,
) => {
    switch (action.type) {
        case 'SetCurrentShowCode': {
            return {
                ...state,
                lastFilterChangeEpochMilliseconds: getCurrentTimeEpochMilliseconds(),
            };
        }
        case 'ShowPlaylistSyncSuccess': {
            return {
                ...state,
                songs: action.songs.map<ISongRequestLocal>(s => {
                    return {
                        ...s,
                        id: Guid.create().toString(),
                        updateStatus: 'NONE',
                    } as ISongRequestLocal;
                }),
            };
        }
        case 'UpdateSongPlayedStatusSent': {
            let song = state.songs.filter(s => s.id === action.songRequestId)[0];
            const allSongsButSelected = state.songs.filter(s => s.id !== action.songRequestId);

            song = {
                ...song,
                updateStatus: 'SENDING',
            };

            return {
                ...state,
                songs: [ ...allSongsButSelected, song ],
            };
        }
        case 'UpdateSongPlayedStatusSuccess': {
            let song = state.songs.filter(s => s.id === action.songRequestId)[0];
            const allSongsButSelected = state.songs.filter(s => s.id !== action.songRequestId);

            song.requests.forEach(r => r.played = action.newValue);

            song = {
                ...song,
                updateStatus: 'SUCCESS',
                requests: song.requests,
            };

            return {
                ...state,
                songs: [ ...allSongsButSelected, song ],
                lastFilterChangeEpochMilliseconds: getCurrentTimeEpochMilliseconds(),
            };
        }
        case 'UpdateSongPlayedStatusFailed': {
            let song = state.songs.filter(s => s.id === action.songRequestId)[0];
            const allSongsButSelected = state.songs.filter(s => s.id !== action.songRequestId);

            song = {
                ...song,
                updateStatus: 'FAILED',
            };

            return {
                ...state,
                songs: [ ...allSongsButSelected, song ],
            };
        }
        default:
            neverReached(action); // when a new action is created, this helps us remember to handle it in the reducer
    }
    return state;
};