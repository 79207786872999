import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Grid, FormControl } from '@material-ui/core';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import CurrencyInput from '../UserInterface/CurrencyInput';
import { IAppState } from '../../store';

const styles = (theme: Theme) => createStyles({
    input: {
        boxShadow: 'none',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        width: '6em',
        '&.selected': {
            borderColor: theme.palette.primary.main,
        }
    },
});

interface IProps extends WithStyles<typeof styles> {
    tipAmount: number | undefined;
    setTipAmt: (tipAmtCadCents: number | undefined) => void;
    disabled: boolean;
}

const TipAmount: React.FC<IProps> = ({
    tipAmount,
    setTipAmt,
    disabled,
    classes,
}) => {
    const setTipAmtCadCentsCallback = React.useCallback((tipAmtCadCents: number) => {
        if (Number.isNaN(tipAmtCadCents)) {
            setTipAmt(undefined);
        }
        else {
            setTipAmt(tipAmtCadCents);
        }
    }, [setTipAmt]);

    return <Grid item>
        <FormControl fullWidth variant="outlined">
            <CurrencyInput
                className={`${classes.input}${tipAmount !== undefined ? " selected" : ""}`}
                onValueChange={(e: number) => setTipAmtCadCentsCallback(e)}
                value={tipAmount === undefined ? 0 : tipAmount}
                currency="$"
                disabled={disabled}
            />
        </FormControl>
    </Grid>;
};

const mapStateToProps = (store: IAppState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
    };
};

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps,
)(TipAmount));