import * as React from 'react';
import { reduxForm, InjectedFormProps, Field } from 'redux-form';
import { Button, Theme, createStyles, WithStyles, withStyles, Paper, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { requiredWithMessage } from './Form/formValidators';
import { renderCheckbox, renderHideableMultilineTextField, renderTextField } from './Form/muiReduxFormIntegration';
import { BoxWithHidden, CircularProgressWithHidden, TypographyWithHidden } from './HighOrderComponents/AddHidden';
import { IAppState } from '../store';
import TipDisplay from './TipDisplay';
import { priceInCadCentsToString } from '../util';
import { ISongRequest, sendSongRequestActionCreator } from '../store/showInfo';

export const RequestFormName: string = 'requestForm';
const minTipAmt: number = process.env.REACT_APP_MIN_TIP_AMT_CENTS === undefined ? 0 : parseInt(process.env.REACT_APP_MIN_TIP_AMT_CENTS);

const styles = (theme: Theme) => createStyles({
    root: {
        '& .MuiFormControl-root': {
            width: '100%',
        },
    },
    creditCardContainer: {
        padding: theme.spacing(1),
        margin: `${theme.spacing(1)}px 0`,
    },
    tac: {
        '& .MuiFormControlLabel-label': theme.typography.subtitle2,
    },
    tacLinks: {
        "& a p": {
            display: 'inline-block',
        }
    },
});

interface IFormProps extends WithStyles<typeof styles> {
    allowTips: boolean;
    loading: boolean;
    stripeError: string | undefined;
    tipError: string | undefined;
    submitError: string | undefined;
    tipAmount: number | undefined;
    setTipAmount: (amt: number | undefined) => void;
    clearOrder: () => void;
}

export interface ISendRequestForm {
    artistName: string;
    songName: string;
    dedication: string;
    includeTip: boolean;
}

const artistNameRequiredMessage = requiredWithMessage("Woops! Looks like you forgot the name of the artist.");
const songNameRequiredMessage = requiredWithMessage("Name that jam! What's the song you'd like to hear?");

const FormComponent: React.FC<InjectedFormProps<ISendRequestForm, IFormProps> & IFormProps> = ({
    classes,
    handleSubmit,
    pristine,
    submitting,
    stripeError,
    tipError,
    submitError,
    tipAmount,
    setTipAmount,
    allowTips,
    loading,
    clearOrder,
}) => {
    const [includeTip, setIncludeTip] = React.useState(false);
    const toggleIncludeTip = React.useCallback(() => {
        setIncludeTip(!includeTip);
    }, [setIncludeTip, includeTip]);

    return (
        <form className={classes.root} onSubmit={handleSubmit}>
            <Field
                name="artistName"
                component={renderTextField}
                type="text"
                label="Artist"
                disabled={submitting || loading}
                margin="normal"
                validate={[artistNameRequiredMessage]}
            />
            <Field
                name="songName"
                component={renderTextField}
                type="text"
                label="Song"
                disabled={submitting || loading}
                margin="normal"
                validate={[songNameRequiredMessage]}
            />
            <Field
                name="dedication"
                component={renderHideableMultilineTextField}
                type="text"
                label="Dedication"
                disabled={submitting || loading}
                margin="normal"
                multiline
            />
            {allowTips && <>
                <Field
                    color="primary"
                    name="includeTip"
                    variant="contained"
                    component={renderCheckbox}
                    disabled={submitting || loading}
                    label={`Include a tip`}
                    onChange={() => toggleIncludeTip()}
                />
                <BoxWithHidden hidden={!includeTip}>
                    <TipDisplay 
                        currTipAmtCadCents={tipAmount}
                        setTipAmtCadCents={(newAmt: number | undefined) => setTipAmount(newAmt)}
                        tipOptions={[500, 1000, 2000]}
                        disabled={submitting || loading}
                    />
                    <TypographyWithHidden color="error" hidden={tipError === undefined}>{tipError}</TypographyWithHidden>
                    <Paper className={classes.creditCardContainer}>
                        <CardElement />
                    </Paper>
                    <TypographyWithHidden color="error" hidden={stripeError === undefined}>{stripeError}</TypographyWithHidden>
                </BoxWithHidden>
            </>}
            <TypographyWithHidden color="error" hidden={submitError === undefined}>{submitError}</TypographyWithHidden>
            <Grid container direction="row" alignItems="center" justify="center">
                <Button
                    size="small"
                    variant="contained"
                    color="primary" 
                    aria-label="Send Request" 
                    type="submit"
                    disabled={pristine || submitting || loading}>
                    Send Request
                </Button>
                {/* <Button
                    size="small"
                    variant="contained"
                    color="secondary" 
                    aria-label="clear order"
                    onClick={() => clearOrder()}>
                    Clear All
                </Button> */}
                <CircularProgressWithHidden hidden={!submitting && !loading} />
            </Grid>
        </form>);
};

const ConnectedFormComponent = withStyles(styles)(reduxForm<ISendRequestForm, IFormProps>({
    form: RequestFormName,
})(FormComponent));

interface IProps {
    allowTips: boolean;
    showRequestError: string | undefined;
    showRequestSending: boolean;
    sendSongRequest: (songRequest: ISongRequest) => void;
}

const SendRequestForm: React.FC<IProps> = ({
    allowTips,
    showRequestError,
    showRequestSending,
    sendSongRequest,
}) => {
    const stripe = useStripe();
    const elements = useElements();

    const [stripeErrorMessage, setStripeErrorMessage] = React.useState<string | undefined>(undefined);
    const [tipErrorMessage, setTipErrorMessage] = React.useState<string | undefined>(undefined);

    const [tipAmount, setTipAmount] = React.useState<number | undefined>(undefined);

    const submitCallback = React.useCallback(async (values: ISendRequestForm) => {
        setStripeErrorMessage(undefined);
        setTipErrorMessage(undefined);
        if (values.includeTip && (tipAmount === undefined || tipAmount < minTipAmt)) {
            setTipErrorMessage(`Please, sir. Can I have some more? (We only accept tips over ${priceInCadCentsToString(minTipAmt)}).`);
        }
        else if (stripe !== null && elements !== null) {
            const card = elements.getElement(CardElement);
            let stripePaymentId: string | undefined = undefined;
            if (card !== null) {
                const result = await stripe.createPaymentMethod({
                    type: 'card',
                    card: card,
                    billing_details: {
                        // Include any additional collected billing details.
                    },
                });

                if (result === undefined || result.error || result.paymentMethod === undefined || result.error) {
                    if (result.error) {
                        setStripeErrorMessage(result.error.message);
                    }
                    else {
                        setStripeErrorMessage('Uh oh! We couldn\'t process your credit card. Can you try again?');
                    }
                } else {
                    // Record payment
                    stripePaymentId = result.paymentMethod.id;
                }
            }

            if (tipAmount === undefined || stripePaymentId !== undefined) {
                sendSongRequest({
                    artistName: values.artistName,
                    songName: values.songName,
                    dedication: values.dedication,
                    stripePaymentId: stripePaymentId,
                    tipCents: tipAmount,
                } as ISongRequest);
            }
        }
    }, [elements, stripe, tipAmount, setStripeErrorMessage, sendSongRequest]);

    return (
        <ConnectedFormComponent
            loading={showRequestSending}
            enableReinitialize={true}
            clearOrder={() => console.log('Clear Request')}
            onSubmit={submitCallback}
            stripeError={stripeErrorMessage}
            tipError={tipErrorMessage}
            tipAmount={tipAmount}
            submitError={showRequestError}
            allowTips={allowTips}
            setTipAmount={setTipAmount} />
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
        allowTips: (store.showInfo.currentShowInfo && store.showInfo.currentShowInfo.allowTips) === true,
        showRequestError: store.showInfo.songRequestError,
        showRequestSending: store.showInfo.songRequestStatus === 'SENDING',
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        sendSongRequest: (songRequest: ISongRequest) => dispatch(sendSongRequestActionCreator(songRequest))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SendRequestForm);