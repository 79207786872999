import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Grid, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { priceInCadCentsToString } from '../../util';
import { IAppState } from '../../store';

const styles = (theme: Theme) => createStyles({
});

interface IProps extends WithStyles<typeof styles> {
    selectedTipAmount: number | undefined;
    tipAmount: number;
    setTipAmount: (tipAmount: number) => void;
    disabled: boolean;
}

const TipOptionButton: React.FC<IProps> = ({
    selectedTipAmount,
    tipAmount,
    setTipAmount,
    disabled,
    classes,
}) => {
    const selected: boolean = React.useMemo(() => {
        return selectedTipAmount === tipAmount;
    }, [selectedTipAmount, tipAmount]);

    return <Grid item>
        <Button
            variant={selected ? "contained" : "outlined"}
            color={selected ? "primary" : "default"}
            onClick={() => setTipAmount(tipAmount)}
            disabled={disabled}
        >
            {priceInCadCentsToString(tipAmount)}
        </Button>
    </Grid>;
};

const mapStateToProps = (store: IAppState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
    };
};

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps,
)(TipOptionButton));