import * as React from 'react';
import { Theme, createStyles, WithStyles, withStyles, Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import TipOptionButton from './TipOptionButton';
import TipAmount from './TipAmount';
import { IAppState } from '../../store';

const styles = (theme: Theme) => createStyles({
    customLabel: {
        paddingLeft: '0.5em',
    }
});

interface IProps extends WithStyles<typeof styles> {
    tipOptions: number[] | undefined;
    currTipAmtCadCents: number | undefined;
    setTipAmtCadCents: (tipAmtCadCents: number | undefined) => void;
    disabled: boolean;
}

const TipDisplay: React.FC<IProps> = ({
    tipOptions,
    currTipAmtCadCents,
    setTipAmtCadCents,
    disabled,
    classes,
}) => {
    if (tipOptions === undefined || tipOptions === null || tipOptions.length === 0) {
        return <></>;
    }
    else {
        return <>
            <Grid container justify="center" alignItems="center">
                <Grid item>
                    <Grid container justify="center" alignItems="center">
                        <Typography className={classes.customLabel}>Click a tip amount:</Typography>
                        <Grid item>
                            <Grid container justify="center" alignItems="center">
                                {tipOptions.map(t => <TipOptionButton
                                    key={t}
                                    tipAmount={t}
                                    selectedTipAmount={currTipAmtCadCents}
                                    setTipAmount={setTipAmtCadCents}
                                    disabled={disabled}
                                />)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container justify="center" alignItems="center">
                        <Typography className={classes.customLabel}>... or enter your own:</Typography>
                        <TipAmount tipAmount={currTipAmtCadCents} setTipAmt={setTipAmtCadCents} disabled={disabled}  />
                    </Grid>
                </Grid>
            </Grid>
            <input type="hidden" name="amount" value={currTipAmtCadCents} />
        </>;
    }
};

const mapStateToProps = (store: IAppState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
    };
};

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps,
)(TipDisplay));