import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withStyles, createStyles } from '@material-ui/styles';
import { Theme, WithStyles, Grid } from '@material-ui/core';
import { history, IAppState } from '../store';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import TopNavbar from './TopNavbar';
import HomePage from './HomePage';
import Footer, { footerHeight, footerPadding } from './Footer';
import ShowLandingPage from './ShowLandingPage';
import ShowRequestsPage from './ShowRequestsPage';
import ShowRequestsThanksPage from './ShowRequestsThanksPage';
import ShowPerformersPage from './ShowPerformersPage';
import UrlParameterWrapper from '../components/Wrappers/UrlParameterWrapper';

const styles = (theme: Theme) => createStyles({
    root: {
        minHeight: '100%',
        position: 'relative',
    },
    main: {
        minHeight: '100%',
        position: 'relative',
        paddingBottom: `calc(${footerHeight}px + (2 * ${footerPadding}px))`,
    },
});

export class RootUrls {
    public static readonly home = () => '/';
    public static readonly showHomePage = (showCode: string) => `/${showCode}`;
    public static readonly showRequestPage = (showCode: string) => `/${showCode}/request`;
    public static readonly showRequestThanksPage = (showCode: string) => `/${showCode}/request/thanks`;
    public static readonly showPerformersPage = (showCode: string) => `/${showCode}/performers`;
}

interface IProps extends WithStyles<typeof styles> {
}

const AppRoute: React.FC<IProps> = ({
    classes,
}) => {
    return (
        <div className={classes.root}>
            <ConnectedRouter history={history}>
                <TopNavbar />
                <Grid container component="main" className={classes.main}>
                    <Switch>
                        <Route exact path={RootUrls.home()} component={HomePage} />
                        <Route exact path={RootUrls.showHomePage(":showCode")} render={route => <UrlParameterWrapper newShowCode={route.match.params.showCode}><ShowLandingPage /></UrlParameterWrapper>} />
                        <Route exact path={RootUrls.showRequestPage(":showCode")} render={route => <UrlParameterWrapper newShowCode={route.match.params.showCode}><ShowRequestsPage /></UrlParameterWrapper>} />
                        <Route exact path={RootUrls.showRequestThanksPage(":showCode")} render={route => <UrlParameterWrapper newShowCode={route.match.params.showCode}><ShowRequestsThanksPage /></UrlParameterWrapper>} />
                        <Route exact path={RootUrls.showPerformersPage(":showCode")} render={route => <UrlParameterWrapper newShowCode={route.match.params.showCode}><ShowPerformersPage /></UrlParameterWrapper>} />
                    </Switch>
                </Grid>
                <Footer />
            </ConnectedRouter>
        </div>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
    };
};

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppRoute));