import React from 'react';
import { TextField, RadioGroup, FormHelperText, FormLabel, FormControl, Checkbox, FormControlLabel } from "@material-ui/core";
import HideableTextArea from '../../HideableTextArea';
import DatePicker from 'react-datepicker';
import moment from 'moment';

interface IMeta {
    touched: boolean;
    error: any;
    warning: any;
}

export const renderTextField = (
    { input, label, type, meta: { touched, error, warning }, ...custom }: {
        [x: string]: any;
        input: any;
        label: string;
        type: string;
        meta: IMeta;
    },
) => (
    <TextField
        type={type}
        error={touched && (error !== undefined || warning !== undefined)}
        helperText={touched && error ? error : warning}
        label={label}
        {...input}
        {...custom}
    />
);

export const renderHideableMultilineTextField = (
    { input, label, type, meta: { touched, error, warning }, ...custom }: {
        [x: string]: any;
        input: any;
        label: string;
        type: string;
        meta: IMeta;
    },
) => (
    <HideableTextArea
        type={type}
        error={touched && (error !== undefined || warning !== undefined)}
        helperText={error ? error : warning}
        label={label}
        {...input}
        {...custom}
    />
);

export const renderRadioGroup = (
    { input, label, type, meta: { touched, error, warning }, children, ...custom }: {
        [x: string]: any;
        input: any;
        label: string;
        type: string;
        meta: IMeta;
        children: any;
    }
) => (
    <FormControl component="fieldset" error={touched && (error !== undefined || warning !== undefined)} {...custom}>
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
            {...input}
            value={input.value}
            onChange={(event, value) => input.onChange(value)}
        >
            {children}
            {touched ? <FormHelperText error={touched && (error !== undefined || warning !== undefined)}>{error ? error : warning}</FormHelperText> : <></>}
        </RadioGroup>
    </FormControl>
  )

export const renderDatePicker = (
    {input, label, placeholder, defaultValue, meta: {touched, error, warning} }: {
        [x: string]: any;
        input: any;
        placeholder: any;
        defaultValue: string;
        meta: IMeta;
        children: any;
    }) => (
    <FormControl>
        <FormLabel component="legend">{label}</FormLabel>
        <DatePicker {...input} dateForm="MM/DD/YYYY" selected={input.value ? moment(input.value).toDate() : null} />
        {touched ? <FormHelperText error={touched && (error !== undefined || warning !== undefined)}>{error ? error : warning}</FormHelperText> : <></>}
    </FormControl>
);

export const renderCheckbox = (
    {input, label, className, disabled, color, meta: {touched, error, warning} }: {
        [x: string]: any;
        input: any;
        className: string;
        disabled: boolean;
        meta: IMeta;
        children: any;
        color: any;
    }) => (
    <FormControl className={className}>
        <FormControlLabel
            control={<Checkbox color={color} {...input} />}
            label={label}
            labelPlacement="end"
            disabled={disabled}
        />
    </FormControl>
);