import axios, { AxiosResponse } from 'axios';
import { Guid } from 'guid-typescript';
import { wait } from '../util';

const MAX_TRIES: number = Number(process.env.REACT_APP_NUM_ORDER_RETRIES);
const TIMEOUT_MS: number = Number(process.env.REACT_APP_IDEMPOTENT_TIMEOUT_SECONDS) * 1000;

export async function handleIdempotentApiCall(
    url: string,
    data: any | undefined,
    acceptableResponseCode: number,
    successCallback: (data: any) => void,
    errorCallback?: (code: number | undefined, error: string | undefined) => void,
): Promise<void> {
    const transactionId: string = Guid.create().toString();
    const headers: any = { 
        'Access-Control-Allow-Origin': '*',
        'TransactionId': transactionId,
    };

    let res: AxiosResponse<any> | undefined;
    let errorOccurred: boolean = true;
    let tries = 0;

    while (errorOccurred && tries < MAX_TRIES) {
        if (tries > 0) {
            await wait(5000);
        }

        errorOccurred = false;
        tries++;
        try {
            res = await axios.post(
                url,
                data,
                { headers, timeout: TIMEOUT_MS });
        }
        catch (e) {
            if (e.isAxiosError) {
                res = e.response;
                if (res !== undefined) {
                    errorOccurred = res.status >= 500;
                }
                else {
                    errorOccurred = true;
                }
            }
            else {
                throw e;
            }
        }
    }

    if (res !== undefined && res.status === acceptableResponseCode) {
        successCallback(res.data);
    }
    else if (errorCallback !== undefined) {
        errorCallback(res !== undefined ? res.status : undefined, res !== undefined ? res.data.toString() : undefined);
    }
}
