import React from 'react';
import { Provider } from 'react-redux';
import { configureStore, IAppState } from './store/';
import { Store, AnyAction } from 'redux';
import Route from './route';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import theme from './theme';
import SyncWrapper from './components/Wrappers/SyncWrapper';
import RehydrateWrapper from './components/Wrappers/RehydrateWrapper';
import OfflineWrapper from './components/Wrappers/OfflineWrapper';
import ServiceWorkerUpdateWrapper from './components/Wrappers/ServiceWorkerUpdateWrapper';
import { ThunkDispatch } from 'redux-thunk';
import { updateAvailableActionCreator } from './store/serviceWorker';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const store: Store<IAppState, AnyAction> = configureStore();

export const onServiceWorkerUpdateAvailable = () => {
    (store.dispatch as ThunkDispatch<any, any, AnyAction>)(updateAvailableActionCreator());
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY !== undefined ? process.env.REACT_APP_STRIPE_PUBLIC_KEY : "");

const App: React.FC = () => {
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Provider store={store}>
                <RehydrateWrapper>
                    <OfflineWrapper>
                        <ServiceWorkerUpdateWrapper>
                            <Elements stripe={stripePromise}>
                                <SyncWrapper>
                                    <Route />
                                </SyncWrapper>
                            </Elements>
                        </ServiceWorkerUpdateWrapper>
                    </OfflineWrapper>
                </RehydrateWrapper>
            </Provider>
        </MuiThemeProvider>
    );
}

export default App;
