import { OutlinedInput } from '@material-ui/core';
import React, { FC, KeyboardEvent, useCallback } from 'react';

export type Currency = '$';

interface Props {
    max?: number;
    className?: string;
    onValueChange: (value: number) => void;
    value: number;
    currency: Currency;
    disabled: boolean;
}

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

const CurrencyInput: FC<Props> = ({
    max = Number.MAX_SAFE_INTEGER,
    className = '',
    onValueChange,
    value,
    currency,
    disabled,
}) => {
    const valueAbsTrunc = Math.trunc(Math.abs(value));
    if (value !== valueAbsTrunc || !Number.isFinite(value) || Number.isNaN(value)) {
        throw new Error(`invalid value property`);
    }
    const handleKeyDown = useCallback(
        (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { key, keyCode } = e;
        if (
            (value === 0 && !VALID_FIRST.test(key)) ||
            (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
        ) {
            return;
        }
        const valueString = value.toString();
        let nextValue: number;
        if (keyCode !== DELETE_KEY_CODE) {
            const nextValueString: string = value === 0 ? key : `${valueString}${key}`;
            nextValue = Number.parseInt(nextValueString, 10);
        } else {
            const nextValueString = valueString.slice(0, -1);
            nextValue = nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10);
        }
        if (nextValue > max) {
            return;
        }
        onValueChange(nextValue);
        },
        [max, onValueChange, value]
    );
    const handleChange = useCallback(() => {
        // DUMMY TO AVOID REACT WARNING
    }, []);

    const valueDisplay = (value / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: currency === '$' ? 'USD' : '',
    });

    return (
        <OutlinedInput
            className={className}
            inputProps={{
                onChange: handleChange,
                onKeyDown: handleKeyDown,
                inputMode: "numeric",
                disabled: disabled,
            }}
            value={valueDisplay}
        />);
};

export default CurrencyInput;