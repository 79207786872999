import React from 'react';
import { WithStyles, createStyles, withStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Typography, Grid, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { IAppState } from '../store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootUrls } from '.';
import FollowMeSocialMedia from '../components/FollowMeSocialMedia';

const styles = (theme: Theme) => createStyles({
    root: {
        margin: theme.spacing(3),
        textAlign: 'center',
    },
});

interface IProps extends WithStyles<typeof styles> {
    showCode: string | undefined;
}

const ShowRequestsThanksPage: React.FC<IProps> = ({
    showCode,
    classes,
}) => {
    if (showCode === undefined) {
        return <></>;
    }
    else {
        return (
            <Grid container alignItems="center" direction="column" className={classes.root} spacing={5}>
                <Grid item>
                    <Typography variant="h3">Thank you for your request!</Typography>
                    <Typography variant="subtitle1">Your request has been recorded by our humble request fairies and will be whispered into the performers ear.</Typography>
                </Grid>
                <Grid item>
                    <Button
                        href={RootUrls.showRequestPage(showCode)}
                        color="primary"
                        variant="contained"
                    >
                        Request Another Song
                    </Button>
                </Grid>
                <Grid item>
                    <FollowMeSocialMedia />
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (store: IAppState) => {
    return {
        showCode: store.urlWrapper.currentShowCode,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
    };
};

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShowRequestsThanksPage));