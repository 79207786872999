import React from 'react';
import { withStyles, createStyles } from '@material-ui/styles';
import { AppBar, Theme, WithStyles, Typography, Box, withWidth, WithWidth } from '@material-ui/core';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IAppState } from '../store';
import { push } from 'connected-react-router';

export const headerHeight: string = '80px';
export const headerMobileHeight: string = '70px';

export const mobileDisplay = {
    xs: 'none',
    sm: 'none',
    md: 'block',
    lg: 'block',
};

const styles = (theme: Theme) => createStyles({
    header: {
        overflow: 'hidden',
        padding: theme.spacing(1),
        left: 0,
        [theme.breakpoints.up('sm')]: {
            height: headerHeight,
        },
        [theme.breakpoints.down('sm')]: {
            height: headerMobileHeight,
        },
    },
    primaryToolbar: {
        textAlign: 'center',
    },
    placeholder: {
        padding: 0,
    },
    social: {
        position: 'absolute',
        right: 0,
        paddingRight: theme.spacing(1),
    },
    searchToggle: {
        margin: theme.spacing(1),
    },
    grow: {
        flexGrow: 1,
    },
    webpageName: {
        display: 'inline-block',
        cursor: 'pointer',
        color: theme.palette.secondary.main,
        textTransform: 'lowercase',
        fontFamily: '\'Quicksand\', sans-serif',
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5em',
        }
    },
})

interface IProps extends WithStyles<typeof styles> {
    push: (url: string) => void;
}

const AppRoute: React.FC<IProps & WithWidth> = ({
    push,
    width,
    classes,
}) => {
        return (
            <>
                <AppBar position='fixed'
                        className={classes.header}
                        style={{
                            textAlign: width === 'xl' || width === 'lg' ? "center" : "initial",
                        }}
                        color="default">
                    <Box>
                        <Typography variant="h4" className={classes.webpageName} onClick={() => push("/")}>
                            thehubuzz.com
                        </Typography>
                        <Typography variant="subtitle2">A live link to your performer</Typography>
                    </Box>
                </AppBar>
                <div className={`${classes.header} ${classes.placeholder}`} />
            </>
    );
}

const mapStateToProps = (store: IAppState) => {
    return {
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    return {
        push: (url: string) => dispatch(push(url)),
    };
};

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps,
)(withWidth()(AppRoute)));